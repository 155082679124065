import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import MainSearch from "../components/MainSearch";
import {axios_set_data, shortDate} from "../config"
import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import PaginatedTable from '../components/PaginatedTable';
import LeagueLinks from "../components/LeagueLinks";

const Home = () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    axios_set_data('/api_ranking/search_runners/', setData, (x) => {})
  }, [])

  const racesURL = `/api_ranking/front_page_races/?`;

  const raceHeadLabels = [
    {label: "Date"}, 
    {label: "Race"}];

  const raceDataProcessors = [
      (race) => (shortDate(race.date)),
      (race) => (<Link to = {`/races/${race.id}`}>{race.meet_name + " - " +race.section}</Link>),
    ];
  
  return (
    <Grid className="home" container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" color="textPrimary" gutterBottom>
          Welcome to LACCTiC!
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          <strong>LACCTiC</strong> stands for <strong>L</strong>ogarithmically <strong>A</strong>djusted <strong>C</strong>ross <strong>C</strong>ountry <strong>Ti</strong>me <strong>C</strong>omparisons.
          This website converts performances from varying course difficulties to their track 5k equivalents. 
          The results are used to provide sophisticated rankings and race simulations. So far <strong>{ data && data.count } runners</strong> have been analyzed.
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom> 
          For a tutorial, see the <Link to = "/tutorial/">introduction</Link>. For frequently asked questions, see the <Link to = "/faq/">FAQ</Link>.
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
        If you would like to help sponsor LACCTiC, please <Link to = "/contact/">contact me</Link>.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5"  color="textPrimary" gutterBottom style={{textAlign:"center"}}>
          Preloaded 2024 Nationals Simulations
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          <strong>Division I:</strong> <Link to = "/simulate/teamIDs=1329,2444,1330,795,983,53,1780,2448,956,953,2445,1079,264,671,955,263,2442,957,193,291,260,270,1545,295,748,54,673,1338,57,2443,59&individualRunnerIDs=22207,64314,40514,57642,53307,96333,74465,48596,98887,50057,83027,62308,83801,11316,82603,100145,100144,59039,81538,80269,108900,110518,16503,104429,108865,108862,75202,80437,78646,56169,52203,107995,6428,38078,30466,72318,63923,14560&league=&deleted=">Women</Link> &nbsp;
          <Link to = "/simulate/teamIDs=1340,325,42,1085,320,949,946,1775,796,249,943,945,34,2436,1345,710,32,1314,35,1892,1642,1344,250,2440,708,947,2438,739,142,1837,135,1553&individualRunnerIDs=82796,105261,105262,45033,75176,16586,107970,98987,28411,48860,64734,92381,48859,37462,105841,39287,57055,93839,95353,104585,81670,104467,104469,8710,46176,75183,33640,31300,96343,62297,46642,98604,98603,22101,72503,56725,41263,41264&league=&deleted=">Men</Link>
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          <strong>Division III:</strong> <Link to = "/simulate/teamIDs=2896,963,597,730,1724,1608,550,2466,1725,595,2523,1048,1610,2841,603,1402,1401,2520,522,1428,2524,1607,1400,496,1723,21,2977,2991,1113,375,515,2417&individualRunnerIDs=63034,62331,68415,68343,42556,42801,51196,69662,46595,46749,67043,50542,46632,49939,65518,52188,57132,52185,52184,54112,70193,49678,49677,50474,97311,47467,105749,68538,80802,98366,72356,95604,65757,66860,52906,64792,40073,53944,67804,67808,85620,93918,48695,83634,45196,94277,48657,49180,66761,105192,48334,69867,53792,68460,81912,69863,69402,85383,69812,66933,51012,94327,94330,89423,71223,73242,54891,85382,66947,50655&league=&deleted=">Women</Link> &nbsp;
          <Link to = "/simulate/teamIDs=1044,371,565,1589,2530,1046,2529,567,721,2302,1416,2532,1727,564,1726,1404,570,2897,455,537,1405,553,539,2987,584,569,2468,587,1728,464,581,977&individualRunnerIDs=83353,49611,49823,86273,45927,61730,43098,46576,79321,77919,50609,39900,50915,67025,71901,71917,66203,55919,20744,47664,63513,102416,49751,77025,65013,50505,68560,65112,82773,43513,50210,51121,51119,42064,43924,95083,77720,45290,45291,43887,85986,55948,67948,48773,51051,25811,84105,48776,49124,50959,77237,64828,81939,44791,69849,102511,64254,56690,53892,50991,80769,63191,84364,105494,56876,56699,56883,73997,73253,60202&league=&deleted=57370">Men</Link>
        </Typography>
  </Grid> 
      <Grid item xs={12}>
        <MainSearch/>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1"  color="textPrimary" >
          or
        </Typography>
      </Grid>
      <Grid item xs={12}>
      <Button component = {Link} to='/simulate/' variant="contained" color="secondary" size="large" >Start a New Simulation</Button>
      </Grid>
      <Grid item xs={12}>
      <Typography variant="h5"  color="textPrimary" gutterBottom style={{textAlign:"center"}}>
          Division and Region Links
      </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          Links to conferences are also in the top right of team pages.
        </Typography>
      </Grid>
      <LeagueLinks/>
      <Grid item xs={12} spacing = {2}>
        <PaginatedTable
          url = { racesURL } 
          title = "Recent Races" 
          headLabels = { raceHeadLabels } 
          dataProcessors = { raceDataProcessors } 
          minwidth={500}
        />
      </Grid>
    </Grid>
  )
}

export default Home;